<template>
  <v-container id="generos" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-human-male-female"
      inline
      :title="$t('gender')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Género</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Generos-->
      <v-dialog max-width="500px" v-model="dialog" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Genero -->
                <v-col cols="12">
                  <v-text-field
                    label="Género*"
                    required
                    v-model="editedItem.genero"
                    :error-messages="generoErrors"
                    @blur="$v.editedItem.genero.$touch()"
                    @input="$v.editedItem.genero.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de Generos  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="generos"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de Acciones-->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="generos" :dataFields="fields" fileName="Generos" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  name: 'CatalogoGeneros',
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },

  data: () => ({
    //Variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    //Arrays
    generos: [],
    headers: [
      { text: 'Género', align: 'start', sortable: false, value: 'genero' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    value: [],
    //Objetos
    defaultItem: {
      _id: '',
      genero: '',
      activo: true,
    },
    editedItem: {
      _id: '',
      genero: '',
      activo: true,
    },
    fields: {
      Género: 'genero',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      genero: { required, minLength: minLength(3), maxLength: maxLength(20) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Género' : 'Editar Género'
    },

    // Propiedad computada para errores en campo Genero
    generoErrors() {
      const errors = []
      if (!this.$v.editedItem.genero.$dirty) return errors
      !this.$v.editedItem.genero.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.genero.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.genero.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerGeneros()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let payload = {}
      payload.id = this.editedItem._id
      this.$store
        .dispatch('gender/deleteGender', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de generos
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de generos
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de generos
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.generos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.generos.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar generos
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.generos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todas los generos
     */
    async obtenerGeneros() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('gender/fetchGenders', payload)
        .then((response) => {
          this.generos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un genero nuevo o
     * la edicion de un genero
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          genero: this.editedItem.genero,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          //payload.id = this.editedItem.id
          this.$store
            .dispatch('gender/editGender', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.generos[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('gender/addGender', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.generos.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
        this.changed = false
      }
    },
  },
}
</script>
